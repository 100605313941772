import React from "react";
import { Product } from "../../../domain";
import { ImageComponent } from "@teppixdigital/shared-cms-components";

export type ProductAsset = Pick<Product, "primary_asset" | "summary">;

export const ProductAssetComponent = (props: { asset: ProductAsset }) => {
  const { asset } = props;
  return (
    <ImageComponent
      url="https://i1-vnexpress.vnecdn.net/2023/01/08/fe8e6c353ddfe581bcce-4656-1673-5775-5310-1673151925.jpg?w=680&h=408&q=100&dpr=2&fit=crop&s=Og-iP7iO6u_VUps7UJBbaQ"
      width={"200px"}
      height={"200px"}
      alt={asset.summary || ""}
    />
  );
};
