import React, { ReactNode, useMemo } from "react";

export const PriceItem = (props: {
  from_price?: number;
  to_price?: number;
  highlight?: boolean;
  strikethrough?: boolean;
  separator?: string;
}) => {
  const {
    from_price,
    to_price,
    highlight = false,
    strikethrough = false,
    separator = "-",
  } = props;

  const fromPrice = useMemo(() => {
    if (from_price) {
      let elem: ReactNode = <>{from_price} ₫</>;
      if (highlight) {
        elem = <strong>{elem}</strong>;
      }
      return elem;
    }
    return null;
  }, [highlight, from_price]);

  const toPrice = useMemo(() => {
    if (to_price) {
      let elem: ReactNode = <>{to_price} ₫</>;
      if (highlight) {
        elem = <strong>{elem}</strong>;
      }
      return elem;
    }
    return null;
  }, [highlight, to_price]);

  const styles = useMemo(() => {
    const css = [];
    if (strikethrough) {
      css.push("text_line_through");
    }
    if (highlight) {
      css.push("price_highlight");
    }
    return css.join(" ");
  }, [strikethrough, highlight]);

  return (
    <span className={styles}>
      {fromPrice ? fromPrice : null}
      {toPrice ? <> - {toPrice}</> : null}
    </span>
  );
};
