import React from "react";
import { Column } from "@teppixdigital/shared-cms-components";

import { Product } from "../../../domain";
import { PriceItem } from "../price_item/price_item.component";

export type Price = Pick<
  Product,
  | "sale_price"
  | "original_price"
  | "min_sale_price"
  | "max_sale_price"
  | "price_varies"
>;

export const ProductPriceComponent = (props: { price: Price }) => {
  const { price } = props;
  return (
    <Column>
      <>
        {price.price_varies ? (
          <PriceItem
            from_price={price.min_sale_price}
            to_price={price.max_sale_price}
            highlight
          />
        ) : (
          <PriceItem from_price={price.sale_price} highlight />
        )}
      </>
      <PriceItem from_price={price.original_price} strikethrough />
    </Column>
  );
};
