import React, { useMemo } from "react";
import {
  Row,
  Links,
  Plugin,
  JsonNodePresenterProps,
} from "@teppixdigital/shared-cms-components";

import { Product } from "../../../domain";
import { ProductAssetComponent } from "../product_media/product_media.component";
import { ProductPriceComponent } from "../product_price/product_price.component";

export type ProductSummary = Pick<
  Product,
  | "id"
  | "code"
  | "sale_price"
  | "original_price"
  | "min_sale_price"
  | "max_sale_price"
  | "inventory_policy"
  | "name"
  | "inventory_quantity"
  | "price_varies"
  | "primary_asset"
>;

export const ProductSummaryComponent = (props: {
  product: ProductSummary;
  links?: Links;
}) => {
  const { product, links } = props;
  return (
    <Row>
      <ProductAssetComponent asset={product} />
      <ProductPriceComponent price={product} />
    </Row>
  );
};

export const withProductSummary = (): Plugin => {
  return {
    kind: "product_summary",
    presenterSelector: (ctx) => (props: JsonNodePresenterProps) => {
      const { node, node_meta } = props;
      const product = useMemo<ProductSummary | null>(() => {
        const {
          sale_price,
          original_price,
          min_sale_price,
          max_sale_price,
          price_varies = false,
          inventory_policy,
          inventory_quantity,
          name,
          id,
          code,
        } = node.p as Product;

        return {
          id,
          sale_price,
          original_price,
          min_sale_price,
          max_sale_price,
          inventory_policy,
          price_varies,
          inventory_quantity,
          name,
          code,
        };
      }, [node]);
      return (
        <>
          {product ? (
            <ProductSummaryComponent
              product={product}
              links={node_meta?.links}
            />
          ) : null}
        </>
      );
    },
  };
};
